'use client';
import { useEffect, useRef, useState } from 'react';

import { useImpression } from '@/providers/ImpressionProvider';
import { ActivityType, ImpressionWrapperProps } from '@/types/impressions.types';
import { useAddImpressionMutation } from '@/utils/hooks/impressionHooks';

export const ImpressionWrapper = ({
	moduleId,
	moduleFor,
	children,
	className,
	disableClickTrack,
	disableImpressionTrack,
	impressionThreshold = 0.5
}: ImpressionWrapperProps) => {
	// Ensure the impressionThreshold is within the range of 0 to 1
	if (impressionThreshold < 0 || impressionThreshold > 1) {
		throw new Error('impressionThreshold must be between 0 and 1');
	}

	const { mutate: addImpression } = useAddImpressionMutation();
	const [clickTracked, setClickTracked] = useState<boolean>(false); // State to prevent duplicate click tracking
	const { userId } = useImpression(); // Context hook to get the current user ID (could be a temporary user ID from local storage or a user ID from the session)
	const wrapperRef = useRef<HTMLDivElement>(null);
	const impressionRef = useRef<boolean>(false);
	const isLocalhost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

	// Intersection observer callback to track impressions when element is in view
	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		// Only track impressions in production or development
		if (isLocalhost) {
			return;
		}
		const entry = entries[0];
		if (entry && entry.isIntersecting && entry.intersectionRatio >= impressionThreshold && !impressionRef.current) {
			impressionRef.current = true; // Mark that impression has been recorded
			addImpression({
				user_key: userId,
				activity_type: ActivityType.VIEW,
				module_for: moduleFor,
				module_uuid: moduleId
			});
		}
	};

	// Click handler to track user clicks
	const handleClick = () => {
		// Only track impressions in production or development
		if (isLocalhost) {
			return;
		}
		if (!disableClickTrack && !clickTracked) {
			setClickTracked(true); // Prevent duplicate click tracking
			addImpression({
				user_key: userId,
				activity_type: ActivityType.CLICK,
				module_for: moduleFor,
				module_uuid: moduleId
			});
		}
	};

	useEffect(() => {
		if (!disableImpressionTrack && !isLocalhost) {
			const observer = new IntersectionObserver(handleIntersection, {
				threshold: [impressionThreshold]
			});

			// Observe the wrapper element
			if (wrapperRef.current) {
				observer.observe(wrapperRef.current);
			}

			// Cleanup the observer on component unmount
			return () => {
				if (wrapperRef.current) {
					observer.unobserve(wrapperRef.current);
				}
			};
		}

		return () => {};
	}, [disableImpressionTrack]);

	return (
		<div ref={wrapperRef} onClick={handleClick} className={className}>
			{children}
		</div>
	);
};
