import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import React from 'react';

interface ArticleCardProps {
	imageUrl: string;
	title: string;
	description?: string;
	type?: 'paginationContain' | 'paginationCover' | 'latest' | 'popular';
	showDate?: boolean;
	publishedAt?: string;
	alt?: string;
	isTournaments?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
	imageUrl,
	title,
	description,
	type = 'paginationCover',
	showDate,
	publishedAt,
	alt,
	isTournaments = false
}) => {
	const typeStyles = {
		paginationContain: 'w-full max-w-[382px]',
		paginationCover: 'w-full max-w-[382px]',
		latest: 'w-full max-w-[799px]',
		popular: 'w-full max-w-[382px]'
	};
	const imageAspectRatio = {
		paginationContain: 'aspect-[382/152]',
		paginationCover: 'aspect-[382/326]',
		latest: 'aspect-[799/397]',
		popular: 'aspect-[382/326]'
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
	};

	return (
		<div
			className={`flex cursor-pointer flex-col rounded-lg bg-white p-2 text-gray-900 shadow-md sm:p-4 sm:shadow-transparent ${typeStyles[type]} ${isTournaments ? 'hover:bg-blue-500 hover:text-white' : ''}`}
		>
			<div className={`${imageAspectRatio[type]} overflow-hidden rounded sm:rounded-2xl`}>
				<img
					src={`${getCDNURL(imageUrl || `${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 800, 320)}`}
					alt={alt ? alt : title}
					className={`size-full ${isTournaments ? 'object-contain' : 'object-cover'}`}
				/>
			</div>

			<div>
				{(showDate && type === 'paginationContain') ||
					(type === 'paginationCover' && publishedAt && <p className="my-2 text-xs">{formatDate(publishedAt)}</p>)}
				<h3 className="mt-2 line-clamp-1 text-base font-medium sm:text-xl">{title}</h3>
				{description && <p className="mt-2 hidden text-sm text-gray-500 sm:line-clamp-2">{description}</p>}
			</div>
		</div>
	);
};

export default ArticleCard;
