'use client';

import ScrollToTop from '@pickleballinc/ui/components/pickleball-app/ScrollToTop';
import { usePathname } from 'next/navigation';

export const ScrollToTopWrapper = () => {
	const pathname = usePathname();

	// No Back to Top button for kiosk pages
	if (pathname.includes('/kiosk')) return null;

	return <ScrollToTop isTournament={true} />;
};
