'use client';

import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@pickleballinc/ui/components/tournaments-app/accordion';
import { Button } from '@pickleballinc/ui/components/tournaments-app/button';
import { Dialog, DialogContent, DialogTrigger } from '@pickleballinc/ui/components/tournaments-app/dialog';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app/unstyled-button';
import { useEffect, useRef, useState } from 'react';

import { MobileDatesPicker } from '@/components/MobileDatesPicker';
import { MobileKeywordSearch } from '@/components/MobileKeywordSearch';
import { MobileLocationSearch } from '@/components/MobileLocationSearch';
import { useAppConfig } from '@/providers/applicationConfig/ApplicationConfigProvider';
import { useTournaments } from '@/tournament/lib/context';
import { useImpersonationBarHeight } from '@/utils/hooks/useImpersonationBarHeight';

export const MobileSearchHeader = () => {
	const { pastEvents, dates, keyword, getTournaments, mapArea, placeKeyword, setPlace, setPlaceKeyword, setKeyword } = useTournaments();
	const [searchOpen, setSearchOpen] = useState(false);
	const [active, setActive] = useState('keyword');

	const scrollRef = useRef<HTMLDivElement>(null);
	const datesRef = useRef<HTMLDivElement>(null);

	const {
		config: { isImpersonationInProcess }
	} = useAppConfig();

	const barHeight = useImpersonationBarHeight();

	useEffect(() => {
		if (scrollRef.current && datesRef.current && active === 'dates') {
			scrollRef.current.scroll({
				top: datesRef.current.offsetTop - 220,
				behavior: 'smooth'
			});
		}
	}, [active]);

	return (
		<div style={isImpersonationInProcess ? { top: `${barHeight}px` } : { top: '0' }} className="sticky z-[49]">
			<div className="flex bg-white p-6 py-4 md:hidden">
				<Dialog
					open={searchOpen}
					onOpenChange={(open) => {
						setSearchOpen(open);
						setActive('keyword');
					}}
				>
					<DialogTrigger asChild>
						<div className="flex h-[60px] w-full items-center rounded-md border border-gray-200 bg-white px-5">
							<FontAwesomeIcon icon={faSearch} size="lg" />
							<div className="ml-4 flex-1">
								<div className="text-sm font-semibold">{mapArea ? 'Map area' : placeKeyword || 'Where?'}</div>
								<div className="flex items-center text-xs font-normal text-black/70">
									<span className="mr-0">{keyword ? keyword : 'Keyword'}</span>
									<span className="mx-2 block h-[10px] w-px -skew-x-[25deg] bg-gray-400" />
									<span className="ml-0">
										{dates?.from || dates?.to
											? `${
													dates.from
														? new Date(dates.from).toLocaleDateString('en-US', {
																day: '2-digit', // Numeric day
																month: 'short', // Abbreviated month
																year: '2-digit'
															})
														: ''
												} - ${
													dates.to
														? new Date(dates.to).toLocaleDateString('en-US', {
																day: '2-digit', // Numeric day
																month: 'short', // Abbreviated month
																year: '2-digit'
															})
														: ''
												}`
											: 'Any date'}
									</span>
								</div>
							</div>
						</div>
					</DialogTrigger>
					{searchOpen && (
						<DialogContent className="z-[10999] flex size-full !max-w-full flex-col gap-0 border-none bg-[#F7F7F7] p-0">
							<div className="relative mx-4 mt-0 flex items-center justify-center py-4">
								<UnstyledButton
									type="button"
									className="absolute left-0 top-1/2 -mt-5 flex size-10 items-center justify-center rounded-full border border-gray-200 bg-white outline-none"
									onClick={() => {
										setSearchOpen(false);
									}}
								>
									<FontAwesomeIcon icon={faTimes} width={16} height={16} className="block size-4" />
								</UnstyledButton>
								<span className="text-lg font-semibold underline underline-offset-8">Search</span>
							</div>
							<div className="flex-1 overflow-auto p-4 pb-10" ref={scrollRef}>
								<Accordion value={active} onValueChange={(active) => setActive(active)} type="single" collapsible className="w-full">
									<AccordionItem value="keyword">
										{active !== 'keyword' && (
											<AccordionTrigger asChild>
												<div
													className="my-2 flex justify-between rounded-xl border border-gray-100 bg-white !p-4"
													style={{
														boxShadow: '0 1px 2px rgba(0,0,0,0.05),0 4px 6px rgba(0,0,0,0.03)'
													}}
												>
													Keyword
													{keyword && <div className="text-sm font-semibold">{keyword}</div>}
												</div>
											</AccordionTrigger>
										)}
										<AccordionContent>
											<MobileKeywordSearch
												onSubmit={() => {
													setSearchOpen(false);
												}}
											/>
										</AccordionContent>
									</AccordionItem>
									<AccordionItem value="location">
										{active !== 'location' && (
											<AccordionTrigger asChild>
												<div
													className="my-2 flex justify-between rounded-xl border border-gray-100 bg-white !p-4"
													style={{
														boxShadow: '0 1px 2px rgba(0,0,0,0.05),0 4px 6px rgba(0,0,0,0.03)'
													}}
												>
													Where
													{(mapArea || placeKeyword) && (
														<div className="text-sm font-semibold">{mapArea ? 'Map area' : placeKeyword || ''}</div>
													)}
												</div>
											</AccordionTrigger>
										)}
										<AccordionContent>
											<MobileLocationSearch />
										</AccordionContent>
									</AccordionItem>
									<AccordionItem value="dates">
										{active !== 'dates' && (
											<AccordionTrigger asChild>
												<div
													className="my-2 flex justify-between rounded-xl border border-gray-100 bg-white !p-4"
													style={{
														boxShadow: '0 1px 2px rgba(0,0,0,0.05),0 4px 6px rgba(0,0,0,0.03)'
													}}
												>
													Dates
													{pastEvents && <span className="m-0">Past dates</span>}
													{(dates?.from || dates?.to) && (
														<div>
															{dates.from &&
																new Date(dates.from).toLocaleDateString('en-US', {
																	day: '2-digit', // Numeric day
																	month: 'short', // Abbreviated month
																	year: '2-digit'
																})}
															<span className="mx-2">-</span>
															{dates.to &&
																new Date(dates.to).toLocaleDateString('en-US', {
																	day: '2-digit', // Numeric day
																	month: 'short', // Abbreviated month
																	year: '2-digit'
																})}
														</div>
													)}
												</div>
											</AccordionTrigger>
										)}
										<AccordionContent ref={datesRef}>
											<MobileDatesPicker />
										</AccordionContent>
									</AccordionItem>
								</Accordion>
							</div>
							<div className="flex shrink-0 items-center justify-between border-t border-gray-200 bg-white p-4">
								<Button
									variant="link"
									className="underline"
									onClick={() => {
										setPlace(null);
										setPlaceKeyword('');
										setKeyword(null);
									}}
								>
									Clear all
								</Button>
								<UnstyledButton
									className="rounded-xl bg-blue-500 px-6 py-3 text-sm font-semibold text-white"
									onClick={() => {
										getTournaments();
										setSearchOpen(false);
									}}
								>
									Search
								</UnstyledButton>
							</div>
						</DialogContent>
					)}
				</Dialog>
			</div>
		</div>
	);
};
