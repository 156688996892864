'use client';

import { faAdd, faCheck, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import { Url } from 'next/dist/shared/lib/router/router';
import { useEffect, useState } from 'react';

import { useSession } from '@/lib/SessionProvider';
import { useTournaments } from '@/tournament/lib/context';
import { PageType } from '@/utils/helpers/constants';
import isSsoURL from '@/utils/helpers/helpers';

interface TopButtonBarProps {
	createPerms: {
		ApprovedToCreate: boolean;
		URLPath: string;
		LoggedIn: boolean;
	};
	pageType: PageType;
}

export const TopButtonBar = ({ createPerms, pageType }: TopButtonBarProps) => {
	const { myTournaments } = useTournaments();
	const [createATournamentURL, setCreateATournamentURL] = useState<Url>('');
	const [registeredForURL, setRegisteredForURL] = useState<Url>('');
	const isLocalHost = process.env.NEXT_PUBLIC_PT_URL?.includes('localhost');

	const { session } = useSession();

	useEffect(() => {
		const destinationUrl = new URL(`${process.env.NEXT_PUBLIC_PT_URL}/my-tournaments`);

		if (session?.user && session?.user?.uuid) {
			setRegisteredForURL(destinationUrl.toString());
		} else {
			const url = new URL(`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/`);
			url.searchParams.set('flow', 'SelfService');
			url.searchParams.set('continue', destinationUrl.toString());
			setRegisteredForURL(url);
		}
	}, []);

	// Create a Tournament URL
	useEffect(() => {
		if (session?.user && session?.user?.uuid && createPerms?.ApprovedToCreate) {
			const url = new URL(createPerms.URLPath);
			url.searchParams.set('rurl', window.location.href);
			setCreateATournamentURL(url);
		} else {
			const url = new URL(`https://${process.env.NEXT_PUBLIC_FRONT_URL}`);

			url.hostname = `contact.${url.hostname}`;
			url.pathname += 'contacts/tournament/create';

			setCreateATournamentURL(url);
		}
	}, [createPerms]);

	return (
		<div
			className={`mx-auto grid max-w-screen-2xl grid-cols-2 gap-2 px-2 sm:grid-cols-6 sm:items-center sm:gap-4 sm:px-4 md:flex md:grid-cols-1 md:flex-row md:flex-wrap`}
		>
			{pageType === PageType.HomePage ? (
				<>
					<CustomLink
						href="/search"
						className={`col-span-2 ${session?.user?.uuid ? 'sm:col-span-6' : 'sm:col-span-3'}`}
						aria-label="Find a Tournament"
					>
						<Button
							suffixIcon={<FontAwesomeIcon icon={faSearch} />}
							variant="blue"
							className="w-full justify-center font-bold"
							aria-hidden="true"
						>
							Find a Tournament
						</Button>
					</CustomLink>
					<>
						{isLocalHost && isSsoURL(registeredForURL) ? (
							<CustomLink
								className="col-span-2 sm:col-span-3"
								href={`${process.env.NEXT_PUBLIC_PT_URL}/login`}
								aria-label="Registered For a Tournament"
							>
								<Button variant="secondary" className="w-full justify-center !bg-blue-50 font-bold !text-blue-700" aria-hidden="true">
									My Tournaments
								</Button>
							</CustomLink>
						) : (
							<a
								className="col-span-2 sm:col-span-3"
								href={registeredForURL.toString()}
								rel={isSsoURL(registeredForURL) ? 'nofollow' : undefined}
								aria-label="Registered For a Tournament"
							>
								<Button variant="secondary" className="w-full justify-center !bg-blue-50 font-bold !text-blue-700" aria-hidden="true">
									My Tournaments
								</Button>
							</a>
						)}
					</>

					<a
						className="col-span-2 sm:col-span-3"
						href={`${process.env.NEXT_PUBLIC_PT_URL}/blog/1`}
						rel={`${process.env.NEXT_PUBLIC_PT_URL}/blog/1`}
						aria-label="Pickleball Tournaments Blog"
					>
						<Button variant="secondary" className="w-full justify-center !bg-blue-50 font-bold !text-blue-700" aria-hidden="true">
							Pickleball Tournaments Blog
						</Button>
					</a>

					{/* <Button variant="secondary" size="lg">
		<div>
			<FontAwesomeIcon icon={faHeart} />
		</div>
	</Button> */}

					<a
						className={`col-span-2 md:ml-auto ${session?.user?.uuid ? (createPerms.ApprovedToCreate ? 'hidden md:flex' : 'sm:col-span-6') : 'sm:col-span-3'}`}
						href={createATournamentURL.toString()}
						rel={isSsoURL(createATournamentURL) ? 'nofollow' : undefined}
						aria-label="Create a Tournament"
					>
						<Button prefixIcon={<FontAwesomeIcon icon={faAdd} />} className="w-full justify-center" variant="blue" aria-hidden="true">
							Create a Tournament
						</Button>
					</a>
				</>
			) : pageType === PageType.SearchPage ? (
				<>
					<CustomLink className="col-span-2 sm:col-span-3" href={registeredForURL}>
						<Button
							prefixIcon={myTournaments && <FontAwesomeIcon icon={faCheck} />}
							variant="blue"
							className="w-full justify-center !bg-blue-50 font-bold !text-blue-700"
							aria-hidden="true"
						>
							My Tournaments
						</Button>
					</CustomLink>

					<a
						className={`col-span-2 md:ml-auto ${session?.user?.uuid ? (createPerms.ApprovedToCreate ? 'hidden md:flex' : 'sm:col-span-6') : 'sm:col-span-3'}`}
						href={createATournamentURL.toString()}
						rel={isSsoURL(createATournamentURL) ? 'nofollow' : undefined}
						aria-label="Create a Tournament"
					>
						<Button prefixIcon={<FontAwesomeIcon icon={faAdd} />} className="w-full justify-center" variant={'blue'} aria-hidden="true">
							Create a Tournament
						</Button>
					</a>
				</>
			) : null}
		</div>
	);
};
