export const MINIMUM_DATE = '1/1/1900';
export const PBRACKETS_UPLOADS_ROOT = `${process.env.NEXT_PUBLIC_PBRACKETS_IMAGE_URL}/uploads`;
export const proBracketLevelIDs = [2, 3, 4, 7];
export const ContactReasonMap = {
	VOLUNTEER: 'VOLUNTEER_CONTACT_FORM_REASON',
	REFEREES: 'REFEREE_CONTACT_FORM_REASON',
	SPONSORS: 'SPONSOR_CONTACT_FORM_REASON',
	VENDORS: 'VENDOR_CONTACT_FORM_REASON'
};
export const USER_IMPRESSION_KEY = 'user-impression-key';
export const BLOG_LABEL = 'Pickleball Hub';

export enum PageType {
	SearchPage,
	HomePage
}
//Ticker
export const TICKER_PREFETCH_SIZE = '5';
export const TICKER_SCROLL_SIZE = '100';
export const TICKER_MATCH_CARD_WIDTH = 324;
export const HOMEPAGE_COLUMN_NUMBER_OF_WIDGETS = 10;
export const MATCH_CARDS_BUCKET_PATH = 'matchcards';
export const COURT_PRIORITY = [
	'cc',
	'incc',
	's',
	'gs',
	'sc',
	's1',
	'gs1',
	'sc1',
	's2',
	'gs2',
	'sc2',
	's3',
	'gs3',
	'sc3',
	's4',
	'gs4',
	'sc4',
	's5',
	'gs5',
	'sc5',
	's6',
	'gs6',
	'sc6'
];

export const GOOGLE_AUTOCOMPLETE_SCRIPT_ID = 'google-script';
export const TINYMCE_EDITOR_KEY = '2fhjogepu3pkb5q18hb6hzxgg2ojov07jq1mukahjnp7y0my';

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_APP_ENV === 'prod';
