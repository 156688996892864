'use client';

import RenderAd from '@pickleballinc/ui/components/advertisements/render-ad';
import { useState } from 'react';

import { TopButtonBar } from '@/components/homepage/TopButtonBar';
import { useLocation } from '@/providers/location';
import { ZONE_IDS } from '@/types/ads.types';
import { IS_PRODUCTION, PageType } from '@/utils/helpers/constants';

interface PreSearchSectionProps {
	createPerms: {
		ApprovedToCreate: boolean;
		URLPath: string;
		LoggedIn: boolean;
	};
	pageType: PageType;
}

export const PreSearchSection = ({ createPerms, pageType }: PreSearchSectionProps) => {
	const { location } = useLocation();
	const [numberOfDifferentBannerImages, setNumberOfDifferentBannerImages] = useState<number>();

	return (
		<div className={`flex flex-col gap-2 py-2 sm:gap-4 sm:py-4 ${pageType === PageType.SearchPage ? 'bg-white' : ''}`}>
			<div className="mx-auto w-full px-2 sm:px-4">
				<RenderAd
					zoneId={ZONE_IDS.BANNER}
					pageId={100003}
					place={0}
					ipAddress={location?.query}
					containerClassname="mx-auto"
					numberOfDifferentZoneImages={numberOfDifferentBannerImages}
					setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
					isProd={IS_PRODUCTION}
				/>
			</div>
			<div>
				<TopButtonBar pageType={pageType} createPerms={createPerms} />
			</div>
		</div>
	);
};
