import { useState } from 'react';
import { motion, AnimatePresence, PanInfo } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

interface ImageSliderProps {
	images: string[];
	containerClassNames?: string;
	onImageClick?: (image: string) => void;
	objectCover?: boolean;
	size?: 'sm' | 'md';
	customHref?: string;
}

const ImageSlider = ({ images, containerClassNames, onImageClick, objectCover, size = 'md', customHref }: ImageSliderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [dragDirection, setDragDirection] = useState<'left' | 'right'>('right');

	const nextSlide = () => {
		setDragDirection('right');
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const prevSlide = () => {
		setDragDirection('left');
		setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
	};

	const handleDragEnd = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
		if (info.offset.x < -50) {
			nextSlide();
		} else if (info.offset.x > 50) {
			prevSlide();
		}
	};

	return (
		<div className={clsx('relative w-full overflow-hidden', containerClassNames)}>
			<div className="h-full relative">
				{customHref ? (
					<a href={customHref}>
						<AnimatePresence>
							<motion.img
								onClick={() => images[currentIndex] && onImageClick && onImageClick(images[currentIndex])}
								key={currentIndex}
								src={images[currentIndex]}
								alt={`Slide ${currentIndex + 1}`}
								className={clsx('absolute w-full h-full cursor-pointer', objectCover ? 'object-cover' : 'object-contain')}
								initial={{ opacity: 0, x: dragDirection === 'left' ? -100 : 100 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: dragDirection === 'left' ? 100 : -100 }}
								transition={{ duration: 0.4 }}
								drag="x"
								dragConstraints={{ left: 0, right: 0 }}
								onDragEnd={handleDragEnd}
							/>
						</AnimatePresence>
					</a>
				) : (
					<AnimatePresence>
						<motion.img
							onClick={() => images[currentIndex] && onImageClick && onImageClick(images[currentIndex])}
							key={currentIndex}
							src={images[currentIndex]}
							alt={`Slide ${currentIndex + 1}`}
							className={clsx('absolute w-full h-full cursor-pointer', objectCover ? 'object-cover' : 'object-contain')}
							initial={{ opacity: 0, x: dragDirection === 'left' ? -100 : 100 }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, x: dragDirection === 'left' ? 100 : -100 }}
							transition={{ duration: 0.4 }}
							drag="x"
							dragConstraints={{ left: 0, right: 0 }}
							onDragEnd={handleDragEnd}
						/>
					</AnimatePresence>
				)}

				{/* Arrows */}
				<button
					className={clsx(
						'slider-control absolute top-1/2 transform hover:bg-white/85 bg-white rounded-full -translate-y-1/2 text-gray-500',
						size === 'sm' ? 'w-6 h-6 left-2' : 'text-xl w-8 h-8 left-3'
					)}
					onClick={prevSlide}
				>
					<FontAwesomeIcon className="slider-control-icon" icon={faChevronLeft} />
				</button>

				<button
					className={clsx(
						'slider-control absolute top-1/2 transform hover:bg-white/85 bg-white rounded-full -translate-y-1/2 text-gray-500',
						size === 'sm' ? 'w-6 h-6 right-2' : 'text-xl w-8 h-8 right-3'
					)}
					onClick={nextSlide}
				>
					<FontAwesomeIcon className="slider-control-icon" icon={faChevronRight} />
				</button>
			</div>

			{/* Bullets */}
			<div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
				{images.map((image, index) => (
					<div
						key={image}
						className={`w-[10px] h-[10px] border border-white rounded-full ${index === currentIndex ? 'bg-white' : 'bg-transparent'}`}
					/>
				))}
			</div>
		</div>
	);
};

export default ImageSlider;
