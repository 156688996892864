'use client';

import type { Match } from '@pickleballinc/react-ui/types/Match';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';

import { TickerScroller } from '@/modules/ticker/TickerScroller';
import { TickerSummary } from '@/modules/ticker/TickerSummary';
import { MatchInfoV2, TickerDataInterface } from '@/modules/ticker/types';
import { useRTE } from '@/providers/rte/RTEProvider';
import { RTEMatchInfo } from '@/providers/rte/types';
import { COURT_PRIORITY, TICKER_SCROLL_SIZE } from '@/utils/helpers/constants';
import { formatTickerMatches } from '@/utils/helpers/formatTickerMatches';

const SIT_TIME_AFTER_COMPLETION = process.env.NEXT_PUBLIC_APP_ENV !== 'prod' ? 30000 : 300000;

export function Ticker({ initialMatches, tournament_slug }: { initialMatches: TickerDataInterface; tournament_slug: string }) {
	const { data } = useInfiniteQuery({
		queryKey: ['ticker', tournament_slug],
		queryFn: async ({ pageParam = 1 }) => {
			const response = await fetch(`/api/v2/ticker?current_page=${pageParam}&tournament_slug=${tournament_slug}`);
			const result: { data: TickerDataInterface; statusCode: number; error?: string } = await response.json();

			if (result.statusCode !== 200 || !result.data || result.data.matches.length === 0) {
				throw new Error(result.error || 'No data available');
			}

			return {
				page: pageParam,
				data: result.data
			};
		},
		initialData: {
			pages: [
				{
					page: 1,
					data: initialMatches
				}
			],
			pageParams: [undefined]
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage) => {
			const currentPage = lastPage.page;
			const totalPages = Math.ceil(lastPage.data.totalRecords / +TICKER_SCROLL_SIZE);

			return currentPage < totalPages ? currentPage + 1 : undefined;
		},
		refetchInterval: 3600000, // hard reload ticker matches every 60 minutes (needed because of midnight)
		refetchOnWindowFocus: false
	});

	const [matchesData, setMatchesData] = useState<MatchInfoV2[]>([]);
	const [filteredMatches, setFilteredMatches] = useState<Match[]>([]);
	const [totalRecords, setTotalRecords] = useState<number>(0);

	// when all matches are fetched data is updated, we need to update matchesData with matches that aren't in the first up to 5 prefetched matches
	useMemo(() => {
		setMatchesData((matchesPrev) => {
			const matchesToAdd: MatchInfoV2[] = [];

			// Only add matches that do not already exist in matchesPrev
			data?.pages.forEach((page) => {
				page?.data.matches.forEach((newMatch) => {
					if (matchesPrev.findIndex((match) => match.matchUuid === newMatch.matchUuid) === -1) {
						matchesToAdd.push(newMatch);
					}
				});
			});

			if (matchesToAdd.length > 0) {
				return [...matchesPrev, ...matchesToAdd];
			}

			return matchesPrev;
		});
	}, [data]);

	const { matches } = useRTE();
	const timeoutMapRef = useRef<Map<string, NodeJS.Timeout>>(new Map());

	useEffect(() => {
		setMatchesData((matchesPrev) => {
			const matchesDataCopy = [...matchesPrev];

			matches.forEach((newMatch) => {
				const matchIndex = matchesDataCopy.findIndex((match) => match.matchUuid === newMatch.matchUuid);

				if (matchIndex !== -1) {
					const matchToUpdate = matchesDataCopy[matchIndex];

					let matchMoved = false;

					if (matchToUpdate) {
						const hasDifferences = (Object.keys(newMatch) as (keyof RTEMatchInfo)[]).some(
							(key) => newMatch[key] !== matchToUpdate[key as keyof MatchInfoV2]
						);

						const filteredNewMatch = Object.fromEntries(
							Object.entries({ ...newMatch, changeHappened: hasDifferences }).filter(([_, value]) => value !== undefined)
						);

						if (hasDifferences) {
							if (
								!matchToUpdate.localDateMatchAssignedToCourt &&
								!matchToUpdate.localDateMatchStart &&
								!matchToUpdate.localDateMatchCompleted &&
								filteredNewMatch.localDateMatchAssignedToCourt
							) {
								const lastLiveMatchIndex = matchesDataCopy.findLastIndex((match) => match.matchStatus === 2);
								const insertIndex = lastLiveMatchIndex !== -1 ? lastLiveMatchIndex + 1 : 0;

								matchesDataCopy.splice(matchIndex, 1);
								matchesDataCopy.splice(insertIndex, 0, {
									...matchToUpdate,
									...filteredNewMatch
								} as MatchInfoV2);

								matchMoved = true;
							} else if (matchToUpdate.matchStatus !== 2 && filteredNewMatch.matchStatus === 2) {
								const courtTitle = (filteredNewMatch.courtTitle as string) || matchToUpdate.courtTitle || '';

								const newMatchPriorityIndex = COURT_PRIORITY.indexOf(courtTitle.toLowerCase());

								let insertIndex: number;

								if (newMatchPriorityIndex === -1) {
									insertIndex = matchesDataCopy.findIndex((match) => match.matchStatus !== 2);
									insertIndex = insertIndex === -1 ? matchesDataCopy.length : insertIndex;
								} else {
									insertIndex = matchesDataCopy.findIndex((match) => {
										if (match.matchStatus === 2) {
											const matchPriorityIndex = COURT_PRIORITY.indexOf(match.courtTitle?.toLowerCase() || '');

											return matchPriorityIndex === -1 || newMatchPriorityIndex < matchPriorityIndex;
										}
										return false;
									});

									if (insertIndex === -1) {
										insertIndex = matchesDataCopy.findIndex((match) => match.matchStatus !== 2);
										insertIndex = insertIndex === -1 ? matchesDataCopy.length : insertIndex;
									}
								}

								matchesDataCopy.splice(matchIndex, 1);
								matchesDataCopy.splice(insertIndex, 0, {
									...matchToUpdate,
									...filteredNewMatch
								} as MatchInfoV2);

								matchMoved = true;
							} else if (matchToUpdate.matchStatus !== 4 && filteredNewMatch.matchStatus === 4) {
								const existingTimeout = timeoutMapRef.current.get(matchToUpdate.matchUuid);
								if (existingTimeout) {
									clearTimeout(existingTimeout);
								}

								const timeout = setTimeout(() => {
									setMatchesData((matchesPrev) => {
										const matchesDataCopy = [...matchesPrev];
										const matchIndex = matchesDataCopy.findIndex((match) => match.matchUuid === newMatch.matchUuid);

										if (matchIndex !== -1) {
											const [completedMatch] = matchesDataCopy.splice(matchIndex, 1);
											matchesDataCopy.push(completedMatch as MatchInfoV2);
										}

										return matchesDataCopy;
									});

									timeoutMapRef.current.delete(matchToUpdate.matchUuid);
								}, SIT_TIME_AFTER_COMPLETION);

								timeoutMapRef.current.set(matchToUpdate.matchUuid, timeout);
							}
						}

						if (!matchMoved) {
							matchesDataCopy[matchIndex] = {
								...matchToUpdate,
								...filteredNewMatch
							} as MatchInfoV2;
						}
					}
				}
			});

			return [...matchesDataCopy];
		});
	}, [matches]);

	useEffect(() => {
		return () => {
			timeoutMapRef.current.forEach((timeout) => clearTimeout(timeout));
			timeoutMapRef.current.clear();
		};
	}, []);

	useMemo(() => {
		let totalRecords = 0;

		data?.pages.forEach((page) => {
			if (page?.data?.matches?.length > 0) {
				totalRecords = page.data.totalRecords;
			}
		});

		const filteredMatchesNew = formatTickerMatches({
			matchInfos: matchesData,
			imagesWidth: 22,
			imagesHeight: 22
		});

		setTotalRecords(totalRecords);

		setFilteredMatches(filteredMatchesNew);
	}, [matchesData]);

	if (filteredMatches.length > 0) {
		return (
			<div className="bg-white" id="pb-ticker">
				<div className="mx-auto flex max-w-[1440px]">
					<div className="hidden sm:flex">
						<TickerSummary />
					</div>
					<TickerScroller results={filteredMatches} totalRecords={totalRecords} />
				</div>
			</div>
		);
	}

	return null;
}
