'use client';

import { useOutsideClick } from '@pickleballinc/features/src/tournaments-app/lib/outside-click';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { ClearFilterButton } from '@/components/ClearFilterButton';
import { useTournaments } from '@/tournament/lib/context';

import { FormLabel } from './FormLabel';
import { LocationPopover } from './LocationPopover';

interface LocationProps {
	onSearchActive: (active: boolean) => void;
}

export const Location = ({ onSearchActive }: LocationProps) => {
	const { placeKeyword, setPlaceKeyword, mapArea, keyword, setPlace } = useTournaments();
	const [autocompletePredictions, setAutocompletePredictions] = useState<google.maps.places.AutocompletePrediction[] | null>(null);
	const [apLoading, setApLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const inputRefDebounce = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (mapArea) {
			setAutocompletePredictions(null);
		}
	}, [mapArea]);

	const ref = useOutsideClick(() => {
		setOpen(false);
		onSearchActive(false);
	});

	const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setPlaceKeyword(e.target.value);
		if (inputRefDebounce.current) clearTimeout(inputRefDebounce.current);

		inputRefDebounce.current = setTimeout(() => {
			if (e.target.value.length > 0) {
				setApLoading(true);
				const service = new google.maps.places.AutocompleteService();
				service.getPlacePredictions(
					{
						input: e.target.value,
						types: ['geocode']
					},
					(results, status) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							setAutocompletePredictions(results);
							setApLoading(false);
						}
					}
				);
			} else if (e.target.value.length === 0) {
				setAutocompletePredictions(null);
				setApLoading(false);
			}
		}, 300);
	}, []);

	return (
		<div className="min-w-0 max-w-64" style={{ flex: '1 0 0%' }} ref={ref}>
			<div className="relative -m-px flex min-w-0 items-center" style={{ flex: '1 0 0%' }}>
				<FormLabel as="label">
					<div className="relative z-[1] px-6 py-3.5">
						<div className="pb-0.5 text-xs font-medium leading-[16px]">Where</div>
						<input
							type="text"
							className="m-0 block w-full cursor-pointer bg-transparent text-sm font-medium leading-[1.125rem] outline-none placeholder:font-normal placeholder:text-[#717171] visited:outline-none focus:border-0 focus:outline-0"
							placeholder="Search location"
							onFocus={() => {
								setOpen(true);
								onSearchActive(true);
							}}
							value={!open && mapArea ? 'Map area' : keyword && keyword.length > 0 ? 'Anywhere' : placeKeyword || ''}
							onChange={onInputChange}
						/>
						{placeKeyword && (
							<ClearFilterButton
								onClick={() => {
									setPlaceKeyword('');
									setPlace(null);
									setAutocompletePredictions([]);
								}}
							/>
						)}
					</div>
				</FormLabel>
			</div>
			<LocationPopover
				isVisible={open}
				autocompletePredictions={autocompletePredictions}
				apLoading={apLoading}
				onLocationClick={() => {
					setOpen(false);
				}}
			/>
		</div>
	);
};
