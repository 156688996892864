import clsx from 'clsx';

type FormLabelProps<T extends React.ElementType> = {
	children: React.ReactNode;
	as?: T;
	onClick?: () => void;
	isFirst?: boolean;
	isLast?: boolean;
};

export function FormLabel<T extends React.ElementType = 'div'>({ children, as, onClick, isFirst, isLast }: FormLabelProps<T>) {
	const Tag = as || 'div';

	const classes = clsx(
		"m-0 w-0 cursor-pointer before:pointer-events-none before:absolute before:top-1/2 before:z-0 before:-mt-4 before:block before:h-8 before:border-x before:border-white before:opacity-0 after:absolute after:inset-0 after:rounded-md after:bg-clip-padding after:p-px after:content-[''] hover:before:opacity-100 hover:before:content-[''] hover:after:bg-gray-50",
		{
			'before:inset-x-0': !isFirst && !isLast,
			'before:left-0': isLast,
			'before:right-0': isFirst
		}
	);

	return (
		<Tag className={classes} style={{ flex: '1 0 0%' }} onMouseDown={onClick}>
			{children}
		</Tag>
	);
}
