import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/FullSearchForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/homepage/FeaturedContainer.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/homepage/SliderSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/ImpressionWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/MobileSearchHeader.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/Partners.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/PreSearchSection.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/modules/ticker/Ticker.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/providers/rte/RTEProvider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/tournament/components/PPATournamentCarousel.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/tournament/components/PTBlogSection.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/packages/features/src/tournaments-app/search-header/components/SearchHeader.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/advertisements/components/advertisement-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/pickleball-app/MainHeader.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/pickleball-app/TopNavigation.tsx")