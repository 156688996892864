import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClearFilterButton } from '@/components/ClearFilterButton';
import { useTournaments } from '@/tournament/lib/context';

interface MobileKeywordSearchProps {
	onSubmit: () => void;
}

export const MobileKeywordSearch = ({ onSubmit }: MobileKeywordSearchProps) => {
	const { getTournaments, keyword, setKeyword } = useTournaments();

	return (
		<div className="mt-2 w-full rounded-xl bg-white p-6" style={{ boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)' }}>
			<h1 className="m-0 mb-4 text-xl font-semibold leading-none">Keyword?</h1>
			<div className="relative">
				<FontAwesomeIcon icon={faSearch} className="absolute left-4 top-1/2 -mt-2 size-4" />
				<input
					type="text"
					className="h-14 w-full rounded-xl bg-[#F7F7F7] pl-10 text-base focus:outline-none md:text-sm"
					placeholder="Search locations"
					autoFocus
					onKeyDown={(e) => {
						if (e.key === 'Enter' && e.currentTarget.value.length > 0) {
							getTournaments();
							onSubmit();
						}
					}}
					value={keyword || ''}
					onChange={(e) => {
						setKeyword(e.target.value);
					}}
				/>
				{keyword && keyword.length > 0 && (
					<ClearFilterButton
						className="bg-gray-300 hover:bg-gray-400"
						onClick={() => {
							setKeyword(null);
						}}
					/>
				)}
			</div>
		</div>
	);
};
