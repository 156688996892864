import { useEffect, useState } from 'react';

export const useImpersonationBarHeight = () => {
	const [barHeight, setBarHeight] = useState(0);

	useEffect(() => {
		const barElement = document.querySelector('[data-testid="impersonation-bar"]');
		if (barElement) {
			const height = barElement.getBoundingClientRect().height;
			setBarHeight(height);
		}
	}, []);

	return barHeight;
};
