export const ZONE_IDS = {
	SMALL_CARD: '679331',
	LONG_CARD: '679332',
	BANNER: '679340',
	TOURNEY_CARD: '688399',
	LEAGUE_CARD: '688401',
	MODAL: '721101',
	TOURNEY_SEARCH: '788107',
	MODAL_REDESIGNED: '809175'
};

export interface PlacementInterface {
	banner_id: string;
	width: string;
	height: string;
	alt_text: string;
	accompanied_html: string;
	target: string;
	tracking_pixel: string;
	body: string;
	redirect_url: string;
	refresh_url: string;
	rct: string;
	rcb: string;
	refresh_time: string;
	placement_id: string;
	user_frequency_views: string;
	user_frequency_start: string;
	user_frequency_expiry: string;
	viewable_url: string;
	eligible_url: string;
	is_redirectable: boolean;
	image_url: string;
	impressions_remaining: number;
	has_quota: boolean;
	accupixel_url: string;
}
